import React from 'react';

import SectionIntro from '../../molecules/sectionintro/SectionIntro'; 
import SectionText from '../../molecules/sectiontext/SectionText';
import CardList from '../../organisms/cardlist/CardList';

import './About.scss';

function About({data}) {
  return (
    <div className='about grid'>
      <SectionIntro 
        heading={data.heading ? data.heading : 'About'}
        title={data.title1 ? data.title1 : <>Reach out and <br/> get to know me!</>}
        body={data.body1 ? data.body1 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}
        btn={data.btn ? data.btn : 'inbox@aaronsoler.com'}
        link='mailto:inbox@aaronsoler.com'
      />
      <SectionText 
        title={data.title2 ? data.title2 : 'Pursuing to build scalable web apps that\'ll solve the world\'s problems.'}
        body={data.body2 ? data.body2 : 'I\'ve always had a passion for technology and it was the day I built my first computer that I got the chance to put that to the test. I was only fourteen at the time but as I learned more it led me into more advanced topics.'}
      />
      <CardList />
    </div>
  )
}

export default About;