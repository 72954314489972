import React from 'react';

import SectionIntro from '../../molecules/sectionintro/SectionIntro';
import SkillShowcase from '../../organisms/skillshowcase/SkillShowcase';

import './Skills.scss';

function Skills({data}) {
  return (
    <div className='skills grid'>
      <SectionIntro 
        heading={data.heading}
        title={data.title}
        body={data.body}
        btn={data.btn}
      />
      <SkillShowcase/>
    </div>
  )
}

export default Skills;