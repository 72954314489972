import React from 'react';

import Title from '../../atoms/title/Title';

import './Card.scss';

function Card(props) {
  return (
    <div className='card'>
      <div className='card-container'>
        <div>
          {props.children}
          <Title>{props.title ? props.title : 'This is the card title.'}</Title>
        </div>
      </div>
    </div>
  )
}

export default Card;