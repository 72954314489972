import React from 'react';

import Title from '../../../atoms/title/Title';
import Label from '../../../atoms/label/Label';
import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/textarea/TextArea';
import Submit from '../../../atoms/submit/Submit';
import Arrow from '../../../atoms/icons/arrow/Arrow';

import './EmailForm.scss';

function EmailForm(props) {
  return (
    <div className='email-form'>
      <Title>{props.title ? props.title : 'Send me an email!'}</Title>
      <form>
        <Label>
          Subject
          <Input name='subject'/>
        </Label>
        <Label>
          Message
          <TextArea />
        </Label>
        <Submit />
      </form>
    </div>
  )
}

export default EmailForm;