import React from 'react';

import Img11 from '../../atoms/image/img11/Img11';
import Heading from '../../atoms/heading/Heading';
import Body from '../../atoms/body/Body';

import './WorkItem.scss';

function WorkItem(props) {
  return (
    <div className='work-item'>
      <Img11 image={props.image}/>
      <div className='right'>
        <Heading>{props.heading ? props.heading : 'Heading'}</Heading>
        <Body>{props.body ? props.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}</Body>
      </div>
    </div>
  )
}

export default WorkItem;