import React from 'react';

import Card from '../../molecules/card/Card';
import Computer from '../../atoms/icons/computer/Computer';
import Chip from '../../atoms/icons/chip/Chip';
import Rocket from '../../atoms/icons/rocket/Rocket';

import './CardList.scss';

function CardList(props) {
  return (
    <div className='card-list'>
      <Card title='Full Stack Engineer.'><Computer/></Card>
      <Card title='Hardware Enthusiast.'><Chip/></Card>
      <Card title='FPV Racing Drones.'><Rocket/></Card>
    </div>
  )
}

export default CardList;