import React from 'react';

import placeholder from '../../../../content/images/photo-placeholder.png';

import './Img11.scss';

function Img11(props) {
  return (
    <div className='img11'>
      <div className='img-container'><img src={props.image ? props.image : placeholder} alt='placeholder'/></div>
    </div>
  )
}

export default Img11;