import React from 'react';

import Heading from '../../atoms/heading/Heading';
import Title from '../../atoms/title/Title';
import Body from '../../atoms/body/Body';
import Secondary from '../../atoms/buttons/secondary/Secondary';
import Primary from '../../atoms/buttons/primary/Primary';
import Arrow from '../../atoms/icons/arrow/Arrow';

import './SectionIntro.scss';

function SectionIntro(props) {
  return (
    <div className="section-intro">
      <Heading>- {props.heading ? props.heading : 'Heading'}</Heading>
      <Title>{props.title ? props.title : 'This is a title to the section info title.'}</Title>
      <Body>{props.body ? props.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}</Body>
      <Primary link={props.link ? props.link : '#'}>{props.btn ? props.btn : 'Button'}</Primary>
    </div>
  )
}

export default SectionIntro;