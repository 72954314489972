import React, { useRef } from 'react';

import './Home.scss';

import Nav from '../../components/molecules/nav/Nav';
import Logo from '../../components/atoms/icons/logo/Logo';
import Intro from '../../components/organisms/intro/Intro';
import About from '../../components/organisms/about/About';
import Projects from '../../components/organisms/projects/Projects';
import Skills from '../../components/organisms/skills/Skills';
import Work from '../../components/organisms/work/Work';
import Contact from '../../components/organisms/contact/Contact';
import Footer from '../../components/organisms/footer/Footer';



function Home(props) {

  let template = {
    home: {
      heading: 'Introduction',
      title: <>Full Stack Engineer, <br/> Based in <br/> New York City.</>,
      body: 'I\'ve always had a passion for technology and love to take initiative to start projects that\'ll challenge me to sharpen my broad and deep understanding.',
      btn: 'About me'
    },
    about: {
      heading: 'About',
      title1:  <>Reach out and <br/> get to know me!</>,
      body1: 'If you\'re looking to work with me or chat me please don\'t hesitate to reach out! I\'d be more than happy to talk about any exciting projects or how I can provide your team value.',
      btn: 'inbox@aaronsoler.com',
      title2: 'Pursuing to build scalable web apps that\'ll solve real world problems.',
      body2: 'I\'m a problem solver at heart. The ability to come up with solutions has intrigued me since I\'ve built my first computer and ever since that day I\'ve dove deeper and deeper into tech looking for more hurdles to solve.'
    },
    projects: {
      heading: 'Projects',
      title: <>See what I've worked <br/> on over the years.</>,
      body: 'My curiosity has led me into a variety of differen\'t technologies. Here\'s some of my proudest work as it has challenged me to build some pretty impressive things.',
      btn: 'See more'
    },
    skills: {
      heading: 'Skills',
      title: <>Skills that I've taken <br/> initiative to learn.</>,
      body: 'My list of skills has only begun and plan to learn many more technologies as I pursue my career in full stack web development. Because of the high value placed on my professional learning and development, this growing list of skills will continue to expand as I master many more technologies directed to a career pathway in full stack web development.',
      btn: 'See more'
    },
    work: {
      heading: 'Work',
      title: <>Places I've worked in <br/> the past.</>,
      body: 'I enjoy being in an enviorment where I can aquire new skills and be okay with not knowing everything. I want to surround myself with peers that can contrast my own ideas.',
      btn: 'View resume'
    },
    contact: {
      heading: 'Contact',
      title: <>Looking to collaborate? <br/> get in touch!</>,
      body: 'I\'m always looking for interesting conversation whether that be full stack development, career paths, or news on computer hardware that\'s soon to come out!',
      btn: 'Contact me',
      emailTitle: 'Send me an email!'
    }
  }

  const home = useRef()
  const about = useRef()
  const projects = useRef()
  const work = useRef()
  const contact = useRef()

  return (
    <div id='Home'>
      <header ref={home}>
        <div className="container">
          <Nav />
          <Intro data={template.home}/>
        </div>
      </header>
      <section ref={about} className='about-section' id='about'>
        <div className="container">
          <About data={template.about}/>
        </div>
      </section>
      <section ref={projects} className='project-section' id='projects'>
        <div className='container'>
          <Projects data={template.projects}/>
        </div>
      </section>
      <section className='skill-section'>
        <div className='container'>
          <Skills data={template.skills}/>
        </div>
      </section>
      <section ref={work} className='work-section' id='work'>
        <div className='container'>
          <Work data={template.work}/>
        </div>
      </section>
      <section ref={contact} className='contact-section' id='contact'>
        <div className='container'>
          <Contact data={template.contact}/>
        </div>
      </section>
      <footer>
        <div className='container'>
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default Home;