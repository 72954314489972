import React from 'react';

import SectionIntro from '../../molecules/sectionintro/SectionIntro';
import WorkList from '../../organisms/worklist/WorkList';

import './Work.scss';

function Work({data}) {
  return (
    <div className='work grid'>
      <SectionIntro 
        heading={data.heading ? data.heading : 'About'}
        title={data.title ? data.title : <>Reach out and <br/> get to know me!</>}
        body={data.body ? data.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}
        btn={data.btn ? data.btn : 'inbox@aaronsoler.com'}
        link='https://drive.google.com/file/d/1VV7Un-ngl8_0ZWHxSRDQcsCicKQAneZW/view?usp=sharing'
      />
      <WorkList />
    </div>
  )
}

export default Work;