import React from 'react';

import './Chip.scss';

function Chip(props) {
  return (
    <svg className='chip' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
      <title>Hardware Chip</title>
      <rect stroke={'#fff'} x='80' y='80' width='352' height='352' rx='48' ry='48' fill='none' stroke-linejoin='round' stroke-width='32'/>
      <rect stroke={'#fff'} x='144' y='144' width='224' height='224' rx='16' ry='16' fill='none' stroke-linejoin='round' stroke-width='32'/>
      <path stroke={'#fff'} fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 80V48M336 80V48M176 80V48M256 464v-32M336 464v-32M176 464v-32M432 256h32M432 336h32M432 176h32M48 256h32M48 336h32M48 176h32'/>
    </svg>
  )
}

export default Chip;