import React from 'react';

import SectionIntro from '../../molecules/sectionintro/SectionIntro';
import EmailForm from '../../molecules/forms/emailform/EmailForm';
import Line from '../../atoms/line/Line';

import './Contact.scss';

function Contact({data}) {
  return (
    <div className='contact grid'>
      <SectionIntro 
        heading={data.heading ? data.heading : 'About'}
        title={data.title ? data.title : <>Reach out and <br/> get to know me!</>}
        body={data.body ? data.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}
        btn={data.btn ? data.btn : 'inbox@aaronsoler.com'}
      />
      <EmailForm 
        title={data.emailTitle}
      />
      <Line />
    </div>
  )
}

export default Contact;