import React, { useState } from 'react';

import Img34 from '../../atoms/image/img34/Img34';

import airbnb from '../../../content/images/airbnb-app.png';
import tracker from '../../../content/images/tracker-showcase.png';
import bug from '../../../content/images/lambda_bug_tracker.png';
import mission from '../../../content/images/mission_control.png';
import drone from '../../../content/images/drone.png';
import setup from '../../../content/images/setup.png';
import custompc from '../../../content/images/pc1_0.jpg';
import arduino from '../../../content/images/arduino.png';
import Modal from '../../molecules/modal/Modal';

import './ProjectGallery.scss';

function ProjectGallery(props) {
  
  const modal = [
    {
      image: tracker,
      about: 'Receipt Tracker is a concept app made to help users digitize and track their recepts.',
      title: 'Receipt Tracker',
      tech: ['React', 'Redux', 'Express', 'Knex', 'SQL'],
      response: 'I was tasked with creating a responsive marketing page with the LESS preprocessor for an application, my team was building.',
      link: 'https://github.com/Build-Week-ReceiptTracker'
    },
    {
      image: airbnb,
      about: 'Airbnb Optimal Price is an app that helps landlords price their property based off surrounding listings.',
      title: 'Airbnb Optimal Price',
      tech: ['React', 'Context API', 'Express', 'Knex', 'SQL'],
      response: 'For this application, I was tasked with building out visual components while also taking care of user input and form validation.',
      link: ''
    },
    {
      image: bug,
      about: 'Bug Tracker helps teams keep track and prioritize existing bugs within their projects.',
      title: 'Bug Tracker',
      tech: ['React', 'Redux', 'Firebase', 'Express', 'Knex', 'SQL'],
      response: 'I was lead front-end designer for the application. My team needed something functional and presentable in front of our peers.',
      link: ''
    },
    {
      image: mission,
      about: 'Mission Control was designed to help project managers keep track of their teams and the quality of their code.',
      title: 'Mission Control',
      tech: ['Okta', 'React', 'Context API', 'URQL', 'GraphQL', 'Apollo', 'Prisma', 'Postgres'],
      response: 'Building on additional GraphQL queries and resolvers to handle the creation of user roles and permissions.',
      link: ''
    },
    {
      image: setup,
      about: 'I started building computers when I was fourteen years old and now build them for clients who are interested in high-end machines for gaming or productivity.',
      title: 'Personal Setup',
      tech: ['Hardware'],
      response: 'I started building computers when I was fourteen years old and now build them for clients who are interested in high-end machines for gaming or productivity.',
      link: ''
    },
    {
      image: custompc,
      about: 'I started building computers when I was fourteen years old and now build them for clients who are interested in high-end machines for gaming or productivity.',
      title: 'Clients Computers',
      tech: ['Hardware'],
      response: 'I started building computers when I was fourteen years old and now build them for clients who are interested in high-end machines for gaming or productivity.',
      link: ''
    },
    {
      image: drone,
      about: 'I handpicked high-performance parts for a racing FPV drone that is capable of 100MPH and was also the first project that taught me soldering.',
      title: 'FPV Racing Drones',
      tech: ['Hardware'],
      response: 'I handpicked high-performance parts for a racing FPV drone that is capable of 100MPH and was also the first project that taught me soldering.',
      link: ''
    },
    {
      image: arduino,
      about: 'I program an Arduino to analyze the amplitude of base frequencies and turn it into a light show with 600 programmable LED lights.',
      title: 'LED Music Visualizer',
      tech: ['Hardware'],
      response: 'I program an Arduino to analyze the amplitude of base frequencies and turn it into a light show with 600 programmable LED lights.',
      link: ''
    },
  ]

  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(0)
  
  return (
    <div className='project-gallery'>
      {modal.map((data, index) => {
        return <Img34 show={{setShowModal, showModal}} modal={{setModalData, data: index}} img={data.image}>{data.title}</Img34>
      })}
      {showModal ? <Modal data={{setShowModal, showModal, modal: modal[modalData]}}/> : null}
    </div>
  )
}

export default ProjectGallery;