import React from 'react';

import Arrow from '../../icons/arrow/Arrow';

import './Primary.scss';

function Primary(props) {
  return <a onClick={false} className='primary-btn' href={props.link ? props.link : '#'}>{props.children ? props.children : 'Primary'}<Arrow /></a>
}

export default Primary;