import React from 'react';

import placeholder from '../../../../content/images/photo-placeholder.png';

import './Img43.scss';

function Img43(props) {
  return (
    <div className='img43'>
      <div className='img-container'>{props.children ? props.children : <img src={props.image ? props.image : placeholder} alt='placeholder'/>}</div>
    </div>
  )
}

export default Img43;