import React from 'react';

import './Exit.scss';

function Exit(props) {

  let style = {fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}

  return (
    <button className='exit-icon' onClick={props.handleClick}>
      <svg 
        xmlns='http://www.w3.org/2000/svg' 
        width='512' 
        height='512' 
        viewBox='0 0 512 512'
      >
        <title>ionicons-v5-l</title>
        <line x1='368' y1='368' x2='144' y2='144' style={style}/>
        <line x1='368' y1='144' x2='144' y2='368' style={style}/>
      </svg>
    </button>
  )
}

export default Exit;