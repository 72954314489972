import React from 'react';

import SkillsList from '../../molecules/skillslist/SkillsList';

import './SkillShowcase.scss';

function SkillShowcase(props) {
  return (
    <div className='skill-showcase'>
      <SkillsList 
        heading='Front-End'
        body='HTML / CSS / JavaScript / LESS / SASS / React / Routing /  Forms / Testing'
      />
      <SkillsList 
        heading='Back-End'
        body='Node.js / REST APIs / GraphQL / Express / Authorization / Relational databases / SQL'
      />
      <SkillsList 
        heading='Computers'
        body='Hardware assembly / OS installments / Toubleshooting / Overclocking'
      />
      <SkillsList 
        heading='Drones'
        body='Radio communications / Tuning / Soldering / Custom'
      />
    </div>
  )
}

export default SkillShowcase;