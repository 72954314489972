import React from 'react';

import Title from '../../atoms/title/Title';
import Body from '../../atoms/body/Body';

import './SectionText.scss';

function SectionText(props) {
  return (
    <div className='section-text'>
      <Title>{props.title ? props.title : 'This is a title to the section text title.'}</Title>
      <Body>{props.body ? props.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non quam lorem scelerisque bibendum netus. Egestas auctor netus vel dolor cras eget ullamcorper.'}</Body>
    </div>
  )
}

export default SectionText;