import React, { useState } from 'react';

import Logo from '../../atoms/icons/logo/Logo';
import Menu from '../../atoms/icons/menu/Menu';
import Exit from '../../atoms/icons/exit/Exit';
import Secondary from '../../atoms/buttons/secondary/Secondary';
import Primary from '../../atoms/buttons/primary/Primary';

import './Nav.scss';

function Nav(props) {

  let [displayMobileNav, setDisplayMobileNav] = useState(false)

  function toggleNav() {
    setDisplayMobileNav(!displayMobileNav)
  }

  return (
    <>
      <nav className='navigation'>
        <Logo />
        <ul>
          <li><Secondary link='#about'>About</Secondary></li>
          <li><Secondary link='#projects'>Projects</Secondary></li>
          <li><Secondary link='#work'>Work</Secondary></li>
          <li><Primary link='#contact'>Contact</Primary></li>
        </ul>
      </nav>

      <div className='mobile-nav'>
        <Logo />
        <Menu handleClick={toggleNav}/>
      </div>

      {displayMobileNav ? 
      <nav className='mobile-nav-overlay'>
        <Exit handleClick={toggleNav}/>
        <ul>
          <li onClick={() => setDisplayMobileNav(!displayMobileNav)}><Secondary link='#about'>About</Secondary></li>
          <li onClick={() => setDisplayMobileNav(!displayMobileNav)}><Secondary link='#projects'>Projects</Secondary></li>
          <li onClick={() => setDisplayMobileNav(!displayMobileNav)}><Secondary link='#work'>Work</Secondary></li>
          <li onClick={() => setDisplayMobileNav(!displayMobileNav)}><Primary link='#contact'>Contact</Primary></li>
        </ul>
      </nav> 
      : null}
    </>
  )
}

export default Nav;