import React from 'react';

import './Computer.scss';

function Computer(props) {
  return (
  <svg className='computer' fill={'#fff'} stroke={'#fff'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <title>Desktop</title>
    <rect stroke={'#fff'} x='32' y='64' width='448' height='320' rx='32' ry='32' fill='none' stroke-linejoin='round' stroke-width='32'/>
    <path stroke={'#fff'} stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M304 448l-8-64h-80l-8 64h96z'/>
    <path stroke={'#fff'} fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 448H144'/>
    <path stroke={'#fff'} d='M32 304v48a32.09 32.09 0 0032 32h384a32.09 32.09 0 0032-32v-48zm224 64a16 16 0 1116-16 16 16 0 01-16 16z'/>
  </svg>
  )
}

export default Computer;