import React from 'react';

import './Menu.scss';

function Menu(props) {

  // fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px

  let style = {fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}

  return (
    <button className='menu-icon' onClick={props.handleClick}>
      <svg 
        className='menu-icon' 
        xmlns='http://www.w3.org/2000/svg' 
        width='512' 
        height='512' 
        viewBox='-44 0 512 512'
      >
        <title>ionicons-v5-j</title>
        <line x1='80' y1='160' x2='344' y2='160' style={style}/>
        <line x1='80' y1='256' x2='344' y2='256' style={style}/>
        <line x1='80' y1='352' x2='344' y2='352' style={style}/>
      </svg>
    </button>
  )
}

export default Menu;