import React from 'react';

import LargeTitle from '../../atoms/large-title/LargeTitle';
import GitHub from '../../atoms/icons/github/GitHub';
import Linkedin from '../../atoms/icons/linkedin/Linkedin';
import YouTube from '../../atoms/icons/youtube/YouTube';
import Line from '../../atoms/line/Line';

import './Name.scss';

function Name(props) {
  return (
    <div className="name">
      <LargeTitle>Aaron Soler</LargeTitle>
      <Line />
      <ul>
        <li><a href='https://github.com/AaronShawnSoler'><GitHub /></a></li>
        <li><a href='https://www.linkedin.com/in/aaronsoler/'><Linkedin /></a></li>
        <li><a href='https://www.youtube.com/channel/UCfvR3ZrRkotTK3yFT2Zl7WQ'><YouTube /></a></li>
      </ul>
    </div>
  )
}

export default Name;