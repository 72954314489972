import React from 'react';

import placeholder from '../../../../content/images/photo-placeholder.png';
import Heading from '../../../atoms/heading/Heading';

import './Img34.scss';

function Img34(props) {
  return (
    <div className='img34'>
      <div 
        onClick={() => {
          if(props.show) {
            props.show.setShowModal(!props.show.showModal)
          }
          if(props.modal) {
            props.modal.setModalData(props.modal.data)
          }
        }} 
        className='img-container'
      >
        <img src={props.img ? props.img : placeholder} alt='placeholder' />
        <Heading>{props.children ? props.children : 'Title'}</Heading>
      </div>
    </div>
  )
}

export default Img34;