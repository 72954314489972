import React from 'react';

import Name from '../../molecules/name/Name';
import SectionIntro from '../../molecules/sectionintro/SectionIntro';

import './Intro.scss';

function Intro({data}) {
  return (
    <div className='intro grid'>
      <Name />
      <SectionIntro 
        heading={data.heading}
        title={data.title}
        body={data.body}
        btn={data.btn}
      />
    </div>
  )
}

export default Intro;