import React from 'react';

import Logo from '../../atoms/icons/logo/Logo';
import Secondary from '../../atoms/buttons/secondary/Secondary';
import GitHub from '../../atoms/icons/github/GitHub';
import Linkedin from '../../atoms/icons/linkedin/Linkedin';
import YouTube from '../../atoms/icons/youtube/YouTube';

import './Footer.scss';

function Footer(props) {
  return (
    <div className='footer'>
      <nav>
        <Logo />
        <ul>
          <li><Secondary link='#about'>About</Secondary></li>
          <li><Secondary link='#projects'>Projects</Secondary></li>
          <li><Secondary link='#work'>Work</Secondary></li>
          <li><Secondary link='#contact'>Contact</Secondary></li>
        </ul>
      </nav>
      <div className='bottom'>
        <ul>
          <li><a href='https://github.com/AaronShawnSoler'><GitHub /></a></li>
          <li><a href='https://www.linkedin.com/in/aaronsoler/'><Linkedin /></a></li>
          <li><a href='https://www.youtube.com/channel/UCfvR3ZrRkotTK3yFT2Zl7WQ?view_as=subscriber'><YouTube /></a></li>
        </ul>
        <p>Designed and developed by Aaron Soler</p>
      </div>
    </div>
  )
}

export default Footer;

