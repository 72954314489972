import React from 'react';

import Heading from '../../atoms/heading/Heading';
import Body from '../../atoms/body/Body';

import './SkillsList.scss';

function SkillsList(props) {
  return (
    <div className='skills-list'>
      <Heading>{props.heading}</Heading>
      <Body>{props.body ? props.body : 'HTML / CSS / JavaScript / LESS / SASS / React / Routing /  Forms / Testing'}</Body>
    </div>
  )
}

export default SkillsList;