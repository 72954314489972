import React from 'react';

import WorkItem from '../../molecules/workitem/WorkItem';

import NYIT from '../../../content/images/nyit_logo.png';
import UB from '../../../content/images/ub_logo.png';
import Lambda from '../../../content/images/lambda_logo_new.png';

import './WorkList.scss';

function WorkList(props) {
  return (
    <div className='work-list'>
      <WorkItem 
        image={NYIT}
        heading={<><span style={{color: '#f7a81b'}}>NYIT</span>, Researcher</>}
        body='I was a leader for research on autonomous drones and provided the team with a foundation to build onto.'
      />
      <WorkItem 
        image={UB}
        heading={<><span style={{color: '#005dbc'}}>UB</span>, Career Services</>}
        body='I was responsible for hosting info sessions for employers and also providing students with opportunities.'
      />
      <WorkItem 
        image={Lambda}
        heading={<><span style={{color: '#ec3944'}}>Lambda</span>, Team Lead</>}
        body='I collaborated with students to ensure code reviews and coordinated additional support for students via escalations.'
      />
    </div>
  )
}

export default WorkList;