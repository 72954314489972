import React from 'react';

import SectionIntro from '../../molecules/sectionintro/SectionIntro';
import Img43 from '../../atoms/image/img43/Img43';
import ProjectGallery from '../../molecules/projectgallery/ProjectGallery';
import Line from '../../atoms/line/Line';
import ProjectSVG from '../../../content/svg/undraw_project_completed_w0oq.svg';

import './Projects.scss';

function Projects({data}) {
  return (
    <div className='projects grid'>
      <SectionIntro 
        heading={data.heading}
        title={data.title}
        body={data.body}
        btn={data.btn}
      />
      <Img43 image={ProjectSVG}/>
      <Line />
      <ProjectGallery />
    </div>
  )
}

export default Projects;