import React from 'react';

import Arrow from '../icons/arrow/Arrow';

import './Submit.scss';


function Submit(props) {
  return <input className='submit' type='submit' value='Send email'/>
}

export default Submit;