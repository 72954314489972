import React from 'react';

import './Logo.scss';

function Logo(props) {

  let svg = {clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5'}
  let circle = {fill: 'none', stroke: '#fff', strokeWidth: '25px'}
  let path = {fill: 'none', stroke: '#fff', strokeWidth: '35px'}
  return (
    <svg className="logo" width="100%" height="100%" viewBox="0 0 800 800" version="1.1" xmlns="http://www.w3.org/2000/svg" style={svg}>
      <g>
        <g transform="matrix(1.60804,0,0,1.60804,-177.327,-107.722)">
          <circle cx="359.025" cy="315.739" r="233.203" style={circle}/>
        </g>
        <path d="M135,135L665,665" style={path}/>
        <path d="M135,135L135,665" style={path}/>
        <path d="M665,665L665,135" style={path}/>
        <path d="M532.5,135C605.629,135 665,194.371 665,267.5C665,340.629 605.629,400 532.5,400" style={path}/>
        <path d="M532.5,400L135,400" style={path}/>
        <path d="M532.5,135L135,135" style={path}/>
      </g>
    </svg>
  )
}

export default Logo