import React from 'react';

import './Modal.scss';

import Title from '../../atoms/title/Title';
import Body from '../../atoms/body/Body';
import Heading from '../../atoms/heading/Heading';
import Img34 from '../../atoms/image/img34/Img34';
import Exit from '../../atoms/icons/exit/Exit';

function Modal(props) {
  return (
    <div className='modal'>
      <div className='modal-container'>
        <Exit handleClick={() => props.data.setShowModal(false)}/>
        <div className='modal-info'>
          <Img34 img={props.data.modal.image}/>
          <div className='modal-text'>
            <Title>{props.data.modal.title}</Title>
            <Body>{props.data.modal.about}</Body>
            <Heading>Tech used</Heading>
            <ul className='list'>
              {props.data.modal.tech.map(data => <li>{data}</li>)}
            </ul>
            <Heading>Responsibilities</Heading>
            <Body>{props.data.modal.response}</Body>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;